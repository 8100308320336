import { Button } from "react-bootstrap"
import { useNavigate } from "react-router-dom"

export default ({pageName}: {pageName: string}) => {
  const navigate = useNavigate();
  
  if (pageName === "Home") {
    return (
      <>
        <Button className="nav-button" onClick={() => navigate("/leaderboard")}>Leaderboard</Button>
        <Button className="nav-button" onClick={() => navigate("/attendance")}>Attendance</Button>
        <Button className="nav-button" onClick={() => navigate("/analytics")}>Analytics</Button>
      </>
    );
  }
  return <Button className="nav-button" onClick={() => navigate("/")}>Home</Button>
}