import { useState } from "react";
import "../index.css";
import { User } from "../data/Types";

interface Props {
	width: string;
	height: string;
	users: User[];
	onItemSelected: Function;
}

export default (props: Props) => {
	const [matches, setMatches] = useState<string[]>([]);
	const [display, setDisplay] = useState<string>("");
	
	const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;

		props.onItemSelected(value);

		if (value === "") {
			setMatches([]);
		} else {
			let matches: string[] = [];

			props.users.forEach(val => {
				if (val.name.toLowerCase().includes(value.toLowerCase())) {
					matches.push(val.name);
				}

				if (val.name.toLowerCase() === value.toLowerCase()) {
					matches = [];
				}
			});
			setMatches(matches);
		}
		setDisplay(value)
	}

	const handleNameClick = (name: string) => {
		setDisplay(name);
		setMatches([]);
		props.onItemSelected(name);
	}

	return (
		<>
			<div>
				<input value={display} id="autocomplete" type="text" style={{width: props.width, height: "35px"}} placeholder="Enter a user..." onChange={(e) => handleInput(e)}/>
				<div style={{backgroundColor: "white", position: "fixed", width: props.width, borderRadius: "0px 0px 5px 5px", maxHeight: props.height, overflowY: "scroll"}}>
					{
						matches
							.sort((a, b) => a.localeCompare(b))
							.map((val, index) => (
							<p 
								style={{color: "black", margin: "0px"}} 
								onClick={() => handleNameClick(val)}
								key={index}
							>
								{val}
							</p>
						))
					}
				</div>
			</div>
		</>
	)
}
