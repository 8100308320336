import { Button } from "react-bootstrap"
import { useNavigate } from "react-router-dom"

export default () => {
    const navigate = useNavigate();

    return (
        <>
            <div className="dark-div">
                <Button className="dark-button" onClick={() => navigate("/admin")}></Button>;
            </div>
        </>
    )

}