import torque_logo from "../imgs/torque_logo.png";

export default () => {
  return (
    <>
      <div className="header">
        <div className="header-logo">
          <img src={torque_logo} alt="" style={{maxWidth: "100%", maxHeight: "100%", padding: ".5rem 0"}} />
        </div>
        <div className="header-text">
          <h3>TORQUE HOURS</h3>
        </div>
      </div>
    </>
  )
}