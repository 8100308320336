import Header from "../components/Header";
import { useEffect, useState } from "react";
import {
  calculateSignedInTime,
  getAllUsers,
  isSignedIn,
} from "../firebase";
import { User } from "../data/Types";
import NavButtons from "../components/NavButtons";
import { Table } from "react-bootstrap";

export default () => {
  const [users, setUsers] = useState<User[]>([]);

  useEffect(() => {
    getAllUsers().then(allUsers => {
      allUsers.forEach(u => {
        u.meetings = new Map(Object.entries(u.meetings));
        u.volunteer = new Map(Object.entries(u.volunteer));
      });

      setUsers(allUsers);
    });
  }, []);

  return (
    <>
      <Header />
      <div>
        <NavButtons pageName="Attendance"/>
      </div>

      <Table
        striped
        bordered
        hover
        style={{ marginTop: "1rem", width: (-0.0269637 * window.innerWidth + 100.289) + "%" }}
      >
        <thead>
          <tr style={{ textAlign: "center" }}>
            <th style={{ color: "white", backgroundColor: "black", width: "30%" }}>Name</th>
            <th style={{ color: "white", backgroundColor: "black", width: "10%" }}>Signed In Time</th>
          </tr>
        </thead>
        <tbody>
          {users
            .filter(user => isSignedIn(user))
            .map((user, index) => (
              <tr key={index} style={{ textAlign: "center" }}>
                <th className="hours-cell" style={{ color: "white", backgroundColor: "black" }}>{user.name}</th>
                <th className="hours-cell" style={{ color: "white", backgroundColor: "black" }}>{calculateSignedInTime(user)}</th>
              </tr>
            ))
          }
        </tbody>
      </Table>
    </>
  )
}